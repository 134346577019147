import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaSearch } from "react-icons/fa";
import { Text } from "@blueprintjs/core";
import { Maybe } from "common-types";
import { Link } from "gatsby";

import LeaderboardTable from "../LeaderboardTable";
import StyledBtn from "../../sharedStyledComponents/styledBtn";
import { CreatePlayer, DeletePlayer } from "../../containers/Layout";
import { OpenEditPlayerDialog } from "../Layout";
import { ILeaderboard, IPlayer, ITeam } from "../../stateUtils";
import {
  StyledContainer,
  StyledForm,
  StyledNameInput,
  StyledSelect,
  StyledScoreInput,
  StyledLeaderboardHeader,
  StyledLeaderboardTitle,
  StyledSearchInputWrapper,
  StyledSearchInput,
  StyledSearchIconWrapper,
  StyledLeaderboardTableWrapper,
} from "./styles";
import firebase from '../firebase';

export interface IRankedPlayerItem {
  player: IPlayer;
  rank: number;
}

export interface IRankedTeamItem {
  team: ITeam;
  rank: number;
}

interface IProps {
  leaderboard: Maybe<ILeaderboard>;
  players: IPlayer[];
  teamId: string;
  teams: ITeam[];
  createPlayer: CreatePlayer;
  deletePlayer: DeletePlayer;
  openEditPlayerDialog: OpenEditPlayerDialog;
  sidebarEnabled: boolean;
}

const sortPlayers = (players: IPlayer[]) => (
  players
    .sort((a, b) => {
      // sort by score in descending order
      if ((a.score - a.handicap) === (b.score - b.handicap)) {
        // Need to figure out how to Show the same rank twice 
        if (a.lastName > b.lastName) {
          return 0;
        } else if (a.lastName < b.lastName) {
          return 0;
        } else {
          return 0;
        }
      } else if ((a.score - a.handicap) < (b.score - b.handicap)) {
        return -1;
      } else {
        return 1;
      }
    })
);

const sortTeams = (teams: []) => (
  teams
    .sort((a, b) => {
      if ((a.score - a.handicap) < b.score - b.handicap) {
        return -1;
      } else {
        return 1;
      }
    })
);

const mapSortedPlayersToRank = (players: IPlayer[]) => (
  players.map((player, index) => ({ rank: index + 1, player }))
);

const filterPlayersBySearchText = (searchText: string, players: IRankedPlayerItem[]) => (
  players
    .filter(({ player }) => `${player.firstName} ${player.lastName}`
      .toLowerCase()
      .includes(searchText.trim().toLowerCase())
    )
);

const mapSortedTeamsToRank = (players: IPlayer[], teams: ITeam[]) => {
  const groupedTeams = groupTeamPlayers(players)
  const teamScore = addTeamScores(groupedTeams, 'score')
  const teamHandicap = addTeamScores(groupedTeams, 'handicap')
  const teamScores = combineObjectsIntoArray(teamScore, teamHandicap)

  const teamScoresWithName = teamScores.map((teamScore) => {
    return { ...teamScore, ...teams.filter(team => team.id === teamScore.teamId)[0]}
  })

  return sortTeams(teamScoresWithName).map((team, index) => ({ rank: index + 1, team }))
};

const addPlayerScores = (players: IPlayer[], scoreType: string) => {
  const scores = Object.values(players).map(player => player[`${scoreType}`])
  return scores.reduce((a, b) => (parseInt(a) + parseInt(b)))
};

const groupTeamPlayers = (teams: IPlayer[]) => {
  return teams.reduce((acc, obj) => {
    const key = obj['teamId'];
    if (!acc[key]) {
       acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

const addTeamScores = (groupedTeams: object, scoreType: string) => {
  let teamScores = {}
  for(const key in groupedTeams) {
    teamScores[key] = { [scoreType]: addPlayerScores(groupedTeams[key], scoreType) }
  }

  return teamScores;
};


const combineObjectsIntoArray = (obj1, obj2) => {
  let mergedArr = []
  for(const key in obj1) {
    mergedArr.push({ ...{ teamId: key }, ...obj1[key], ...obj2[key] })
  }
  return mergedArr;
}

const playersWithTeamInfo = (players, teams) => {
  return players.map((player) => {
    return { ...player, ...teams.filter(team => team.id === player.teamId)[0] }
  })
}

export default ({
  leaderboard,
  teamId,
  teams,
  createPlayer,
  deletePlayer,
  players,
  openEditPlayerDialog,
  sidebarEnabled,
}: IProps) => {
  const [searchText, setSearchText] = useState("");
  const [newPlayer, setNewPlayer] = useState({ 
    firstName: "", 
    lastName: "", 
    coursePlayed:"", 
    teamId:"", 
    handicap:"", 
    score: "",
    leaderboardId: "",
    valid:"",
    company:"",
  })

  if (!leaderboard) return null;
  return (
    <StyledContainer sidebarEnabled={sidebarEnabled}>
      <Formik
        initialValues={{ firstName: "", lastName: "", coursePlayed:"", teamId:"", handicap:"", score: "", valid:"", company:"" }}
        onSubmit={(values, actions) => {
          const { firstName, lastName, coursePlayed, company, teamId } = values;
          const db = firebase.firestore();

          newPlayer.firstName = firstName.trim();
          newPlayer.lastName = lastName.trim();
          newPlayer.company = company.trim();

          newPlayer.coursePlayed = coursePlayed.trim();
          newPlayer.teamId = db.doc(`teams/${teamId}`);
          newPlayer.handicap = values.handicap;
          newPlayer.score = values.score;
          newPlayer.valid = values.valid;
          newPlayer.leaderboardId = db.doc(`leaderboards/${leaderboard.id}`);

          db.collection('players').add(newPlayer).then(() => {
            setNewPlayer(newPlayer)
            actions.resetForm({ firstName: "", lastName: "", coursePlayed:"", teamId:"", handicap:"", score: "", valid:"", company:"" })
            window.location.reload();
          })
          
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .trim()
            .max(100, "Must be less than 100 characters")
            .required("Please input player's first name."),
          lastName: Yup.string()
            .trim()
            .max(100, "Must be less than 100 characters")
            .required("Please input player's last name."),
          handicap: Yup.number()
            .min(-100, "Must be a positive number")
            .max(100, "Must be less that 100")
            .required("Please input player's handicap."),
          score: Yup.number()
            .min(0, "Must be a positive number")
            .max(200, "Must be less that 100")
            .required("Please input player's score."),
          // valid: Yup.boolean()
          //   .required("Please agree to our Terms Of Use and Privacy Policy."),
        })}
        render={props => (
          <StyledForm className="target_form" onSubmit={props.handleSubmit}>
            <StyledNameInput
              id="firstName"
              placeholder="First Initial"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.firstName}
            />

            <StyledNameInput
              id="lastName"
              placeholder="Last Name"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.lastName}
            />
            <StyledSelect
              id="teamId"
              placeholder="Select Team"
              onChange={selectedOption => {
                props.values.teamId = selectedOption.id
              }}
              options={teams}
              getOptionLabel={option => option.name}
            />
            <StyledNameInput
              id="company"
              placeholder="Company Name"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.company}
            />

            <StyledNameInput
              id="coursePlayed"
              placeholder="Course Played"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.coursePlayed}
            />

            

            
            <StyledScoreInput
              id="handicap"
              placeholder="Course Handicap"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={`${props.values.handicap}`}
              type="number"
              min={-100}
              max={100}
            />

            <StyledScoreInput
              id="score"
              placeholder="Gross Score"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={`${props.values.score}`}
              type="number"
              min={0}
              max={1000}
            />

            

            <input
              id="valid"
              placeholder=""
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={`${props.values.valid}`}
              type="checkbox"
            />
            <label className="valid_checkbox">* I acknowledge that I have read the <Link to="/privacy/">Privacy Policy</Link></label>

            <StyledBtn
              type="submit"
              intent="primary"
              disabled={!!Object.entries(props.errors).length || !props.dirty}
              isDisabled={!!Object.entries(props.errors).length || !props.dirty}
              style={{
                textTransform: "uppercase",
              }}
            >
              Submit Score
            </StyledBtn>
          </StyledForm>
        )}
      />
        
    </StyledContainer>
  );
};
