import React from "react";

import Layout from "../containers/Form";

const SubmitPage = () => (
  <>
    <Layout />
  </>
);

export default SubmitPage;
