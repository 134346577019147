import React from "react";
import {
  Dialog,
  FormGroup,
} from "@blueprintjs/core";
import { Formik } from "formik";
import * as Yup from "yup";

import StyledDialogContentWrapper from "../../sharedStyledComponents/styledDialogContentWrapper";
import StyledBtn from "../../sharedStyledComponents/styledBtn";
import {
  StyledFormControlsWrapper,
  StyledInput
} from "./styles";
import { CreateLeaderboard, EditLeaderboard, DeleteLeaderboard } from "../../containers/Layout";
import { ILeaderboard } from "../../stateUtils";

interface ICreateDialogInfo {
  isOpen: boolean;
}

interface IEditDialogInfo {
  isOpen: boolean;
  leaderboardInfo: ILeaderboard;
}

interface IProps {
  createLeaderboard: CreateLeaderboard;
  editLeaderboard: EditLeaderboard;
  deleteLeaderboard: DeleteLeaderboard;
  closeDialog: () => any;
  dialogInfo: ICreateDialogInfo | IEditDialogInfo;
  leaderboardsNotFound: boolean;
}

export default ({
  dialogInfo,
  createLeaderboard,
  editLeaderboard,
  deleteLeaderboard,
  closeDialog,
  leaderboardsNotFound,
}: IProps) => {
  const isEditDialogType =
    (p: any): p is IEditDialogInfo => p.leaderboardInfo;

  const getDialogTitle = (dialogInfoItem: any) => {
    if (isEditDialogType(dialogInfoItem)) {
      return "Edit Leaderboard";
    } else if (leaderboardsNotFound) {
      return "Create First Leaderboard";
    } else return "Create New Leaderboard";
  };

  return (
    <Dialog
      // isOpen={dialogInfo.isOpen}
      // title={getDialogTitle(dialogInfo)}
      // onClose={() => dialogInfo.isOpen && closeDialog()}
      // canEscapeKeyClose={!leaderboardsNotFound}
      // canOutsideClickClose={!leaderboardsNotFound}
      // isCloseButtonShown={!leaderboardsNotFound}
      // portalClassName="app-dialog"
    >
      <StyledDialogContentWrapper>
        <Formik
          initialValues={{
            title: isEditDialogType(dialogInfo) ? dialogInfo.leaderboardInfo.title : "",
          }}
          onSubmit={(values, actions) => {
            const { title } = values;
            if (isEditDialogType(dialogInfo)) {
              editLeaderboard(
                dialogInfo.leaderboardInfo.id,
                { title: title.trim() }
              );
            } else {
              createLeaderboard({ title: title.trim() });
            }

            closeDialog();
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .trim()
              .max(100, "Must be less than 100 characters")
              .required("Please input a leaderboard title."),
          })}
          render={props => (
            <form onSubmit={props.handleSubmit} style={{ marginBottom: "0" }}>
              <FormGroup
                label="Title"
                labelFor="title"
                labelInfo="(Required)"
                intent={props.errors.title ? "danger" : undefined}
                helperText={
                  (props.errors.title && props.touched.title) ?
                    props.errors.title :
                    undefined
                }
              >
                <StyledInput
                  id="title"
                  placeholder="Title..."
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.title}
                />
              </FormGroup>

              <StyledFormControlsWrapper isEditType={isEditDialogType(dialogInfo)}>
                {isEditDialogType(dialogInfo) && (
                  <StyledBtn
                    type="button"
                    intent="danger"
                    onClick={() => {
                      // reset the form in case the last leaderboard has been
                      // deleted and a new one needs to be created while the dialog
                      // is still open
                      props.resetForm({ title: "" });

                      deleteLeaderboard(dialogInfo.leaderboardInfo.id);

                      closeDialog();
                    }}
                    style={{
                      height: "40px",
                    }}
                  >
                    Delete
                </StyledBtn>
                )}

                <StyledBtn
                  type="submit"
                  intent="primary"
                  disabled={isEditDialogType(dialogInfo) ? (
                    !!Object.entries(props.errors).length
                  ) : (
                      !!Object.entries(props.errors).length || !props.dirty
                    )}
                  isDisabled={isEditDialogType(dialogInfo) ? (
                    !!Object.entries(props.errors).length
                  ) : (
                      !!Object.entries(props.errors).length || !props.dirty
                    )}
                  style={{
                    height: "40px",
                  }}
                >
                  Save
                </StyledBtn>
              </StyledFormControlsWrapper>
            </form>
          )}
        />
      </StyledDialogContentWrapper>
    </Dialog>
  );
};
