import styled, { css } from "styled-components";

export const StyledFormControlsWrapper = styled.div<{ isEditType: boolean }>`
  display: flex;
  ${props => props.isEditType ? (
    css`
      & button {
        width: 48%;
        min-width: 48%;
        :first-of-type {
          margin-right: auto;
        }
      }
    `
  ) : (
      css`
      & button {
        width: 100%;
      }
    `
    )}
`;

export const StyledInput = styled.input`
  padding: 10px;
  height: 40px;
  width: 100%;
  font-size: .9rem;
  border-radius: 5px;
  border: 1px solid #8C8989;
`;

