import styled from "styled-components";
import ReactSelect from 'react-select';

import {
  firstBreakpoint,
  secondBreakpoint,
  thirdBreakpoint,
} from "../../../windowBreakpoints";

export const StyledContainer = styled.div<{ sidebarEnabled: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 30px 58px;
  width: 100%;
  // max-width: ${props => props.sidebarEnabled ? "calc(100% - 190px)" : "100%"};
  height: 100%;
  overflow: auto;
  @media (max-width: ${secondBreakpoint}px) {
    height: max-content;
    padding: 30px 28px;
  }
  @media (max-width: ${thirdBreakpoint}px) {
    height: max-content;
    padding: 30px 10px;
  }
`;

export const StyledLeaderboardHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  min-height: max-content;
  overflow: hidden;
  @media (max-width: ${secondBreakpoint}px) {
    flex-direction: column;
  }
`;

export const StyledLeaderboardTitle = styled.div`
  display: flex;
  padding-right: 15px;
  max-width: 70%;
  font-weight: 600;
  font-size: 1.1rem;
  @media (max-width: ${secondBreakpoint}px) {
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledSearchInputWrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  width: 100%;
  max-width:800px;
  margin: 20px auto;
  opacity: ${props => props.isDisabled ? .6 : 1};
  & input {
    cursor: ${props => props.isDisabled ? "not-allowed" : "text"};
  }
  & svg {
    cursor: ${props => props.isDisabled ? "not-allowed" : "default"};
  }
  @media (max-width: ${secondBreakpoint}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledSearchInput = styled.input`
  display: flex;
  padding: 10px;
  padding-left: 35px;
  height: 40px;
  width: 100%;
  font-size: .9rem;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #8C8989;
  outline: none;
  @media (max-width: ${thirdBreakpoint}px) {
    height: 35px;
  }
`;

export const StyledSearchIconWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 13px;
  top: 50%;
  color: rgba(0, 0, 0, .7);
  transform: translateY(-50%);
  max-width:800px;
  margin:auto;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  flex-wrap:wrap;
  background:#fff;
  padding 20px 20px 0px 20px;
  border:1px solid gray;

`;

export const StyledInput = styled.input`
  padding: 10px;
  height: 100%;
  font-size: .9rem;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #8C8989;
  @media (max-width: ${firstBreakpoint}px) {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
  }
`;

export const StyledSelect = styled(ReactSelect)`
  margin-right: 2%;
  @media (max-width: ${firstBreakpoint}px) {
    margin-bottom: 10px;
  }
`;

export const StyledNameInput = styled(StyledInput)`
  margin-right: 2%;
  width: 20%;
  min-width: 20%;
`;

export const StyledScoreInput = styled(StyledInput)`
  margin-right: 2%;
  width: 14%;
  min-width: 138px;
`;

export const StyledLeaderboardTableWrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 100%;
  max-width: 800px;
  margin:auto;
`;
