import React, { useState, useEffect } from "react";
import { Maybe, UUID } from "common-types";
import withSizes, { Sizes } from "react-sizes";

import Header from "../Header";
import Sidebar from "../Sidebar";
import SidebarDrawer from "../SidebarDrawer"
import Leaderboard from "../Leaderboard_Form";
import LeaderboardDialog from "../LeaderboardDialog";
import PlayerDialog from "../PlayerDialog";
import {
  CreatePlayer,
  CreateLeaderboard,
  EditLeaderboard,
  DeleteLeaderboard,
  DeletePlayer,
  EditPlayer,
} from "../../containers/Layout";
import { useSidebarBreakpoint } from "../../windowBreakpoints";
import {
  ILeaderboard,
  IPlayer,
  ITeam
} from "../../stateUtils";
import {
  StyledContainer,
  StyledContentWrapper
} from "./styles";
import "./layout.css";
import { FaTeamspeak } from "react-icons/fa";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import firebase from "../firebase"

export type OpenEditLeaderboardDialog = (leaderboard: ILeaderboard) => void;
export type OpenEditPlayerDialog = (player: IPlayer) => void;


interface IProps {
  leaderboards: ILeaderboard[];
  players: IPlayer[];
  teams: ITeam[];
  createLeaderboard: CreateLeaderboard;
  editLeaderboard: EditLeaderboard;
  deleteLeaderboard: DeleteLeaderboard;
  createPlayer: CreatePlayer;
  editPlayer: EditPlayer;
  deletePlayer: DeletePlayer;
  useMobileSidebar: boolean;
}

const Layout = ({
  createLeaderboard,
  editLeaderboard,
  deleteLeaderboard,
  createPlayer,
  editPlayer,
  deletePlayer,
  useMobileSidebar,
}: IProps) => {
  const [
    mobileSidebarOpen,
    setMobileSidebarOpen,
  ] = useState(false);

  // close the mobile sidebar drawer if it is open
  // and 'useMobileSidebar' is now false
  useEffect(() => {
    if (!useMobileSidebar && mobileSidebarOpen) {
      setMobileSidebarOpen(false);
    }
  }, [useMobileSidebar]);

  const [
    teams,
    setTeams,
  ] = useState([])

  useEffect(() => {
    const fetchTeams = async () => {
      const db = firebase.firestore();
      const data = await db.collection("teams").get()
      setTeams(data.docs.map(doc => {
        return Object.assign({ id: doc.id }, doc.data())
      }))
    }
    fetchTeams()
  }, [])

  const [
    leaderboards,
    setLeaderboards
  ] = useState([])

  useEffect(() => {
    const fetchLeaderboards = async () => {
      const db = firebase.firestore();
      const data = await db.collection("leaderboards").get();
      setLeaderboards(data.docs.map(doc => { 
          return Object.assign({ id: doc.id }, doc.data())
      }));
    }
    fetchLeaderboards()
  }, [])

  const [
    players,
    setPlayers
  ] = useState([])

  useEffect(() => {
    const fetchPlayers = async () => {
      const db = firebase.firestore();
      const data = await db.collection('players').get();
      setPlayers(data.docs.map(doc => {
        const docData = doc.data();

        const playerObj = { 
          ...docData, 
          ...{ id: doc.id }, 
          ...{ teamId: docData.teamId.id }, 
          ...{ leaderboardId: docData.leaderboardId.id}
        }

        return playerObj;
      }));
      
    }
    fetchPlayers()
  }, [])

  const [
    currentLeaderboardId,
    setCurrentLeaderboardId,
  ] = useState<Maybe<UUID>>(leaderboards.length ? leaderboards[0].id : null);

  const [
    leaderboardDialogInfo,
    setLeaderboardDialogInfo,
  ] = useState<{ isOpen: boolean, leaderboardInfo: Maybe<ILeaderboard> }>(
    { isOpen: false, leaderboardInfo: null }
  );

  const [
    editingPlayerInfo,
    setEditingPlayerInfo,
  ] = useState<Maybe<IPlayer>>(null);

  // When the first leaderboard is created it will
  // automatically be selected as the current leaderboard
  useEffect(() => {
    if (leaderboards.length === 1 && !currentLeaderboardId) {
      setCurrentLeaderboardId(leaderboards[0].id);
    }
  }, [leaderboards.length]);

  const openCreateLeaderboardDialog = () => {
    setLeaderboardDialogInfo({ isOpen: true, leaderboardInfo: null });
  };
  const openEditLeaderboardDialog: OpenEditLeaderboardDialog = (leaderboard: ILeaderboard) => {
    setLeaderboardDialogInfo({ isOpen: true, leaderboardInfo: leaderboard });
  };
  const closeLeaderboardDialog = () => {
    setLeaderboardDialogInfo({ isOpen: false, leaderboardInfo: null });
  };

  const openEditPlayerDialog: OpenEditPlayerDialog = (player: IPlayer) => {
    setEditingPlayerInfo(player);
  };
  const closePlayerDialog = () => {
    setEditingPlayerInfo(null);
  };

  const toggleMobileSidebarVisibility = (bool?: boolean) => (
    typeof bool === "boolean" ? setMobileSidebarOpen(bool) : setMobileSidebarOpen(!mobileSidebarOpen)
  );




  return (
    
    <StyledContainer>


      <StyledContentWrapper>
        {!useMobileSidebar && (
          <Sidebar
            currentLeaderboardId={currentLeaderboardId}
            leaderboards={leaderboards}
            openCreateLeaderboardDialog={openCreateLeaderboardDialog}
            openEditLeaderboardDialog={openEditLeaderboardDialog}
            setCurrentLeaderboardId={setCurrentLeaderboardId}
          />
        )}

        <SidebarDrawer
          isOpen={mobileSidebarOpen}
          currentLeaderboardId={currentLeaderboardId}
          leaderboards={leaderboards}
          openCreateLeaderboardDialog={openCreateLeaderboardDialog}
          openEditLeaderboardDialog={openEditLeaderboardDialog}
          setCurrentLeaderboardId={setCurrentLeaderboardId}
          closeDrawer={() => toggleMobileSidebarVisibility(false)}
        />

        {currentLeaderboardId && (
          <Leaderboard
            leaderboard={leaderboards.find(board => board.id === currentLeaderboardId) || null}
            players={players.filter(player => player.leaderboardId === currentLeaderboardId)}
            teams={teams}
            createPlayer={createPlayer}
            deletePlayer={deletePlayer}
            openEditPlayerDialog={openEditPlayerDialog}
            sidebarEnabled={!useMobileSidebar}
          />
        )}
      </StyledContentWrapper>

      <LeaderboardDialog
        dialogInfo={leaderboards.length ? leaderboardDialogInfo : { isOpen: true }}
        leaderboardsNotFound={!leaderboards.length}
        closeDialog={closeLeaderboardDialog}
        createLeaderboard={createLeaderboard}
        editLeaderboard={editLeaderboard}
        deleteLeaderboard={(id: UUID) => {
          if (currentLeaderboardId === id) setCurrentLeaderboardId(null);

          deleteLeaderboard(id);
        }}
      />

      <PlayerDialog
        playerInfo={editingPlayerInfo}
        closeDialog={closePlayerDialog}
        editPlayer={editPlayer}
        teams={teams}
      />
    </StyledContainer>
  );
};

const mapSizesToProps = ({ width }: Sizes) => ({
  useMobileSidebar: width < useSidebarBreakpoint,
});

export default withSizes<{ useMobileSidebar: boolean }, IProps>(mapSizesToProps)(Layout);
